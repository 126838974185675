<template>

<b-modal  @hide="resetForm" id="otpForm" size="md" hide-header hide-footer no-close-on-backdrop centered  modal-class="virtual-tour-dialog"
  content-class="virtual-tour-content" ref="otpFormref">
  <button type="button" class="close margin-fix" data-dismiss="modal" aria-label="Close"
    @click="$refs['otpFormref'].hide()">
    <img src="../assets/images/close-icon-modal.svg" width="30" height="30" class="img-fluid close-icon">
  </button>
  <div class="title">OTP Verification</div>
    <div class="wrapper">
      <div class="sub-title mb-3">Select a User to Login</div>
      <div class="form-input-group col-12 px-0 mb-0">
        <label for="adhaar">USER NAME</label>
        <v-select class="custom-select-tag" :class="{'selected' : bookedBy}" v-model="bookedBy"  :required="!bookedBy" placeholder="Select User" label="display_name" :options="users" @input="handleDeselect"></v-select>
      </div>
    </div>
    <div v-if="bookedBy && otpSent">
      <div class="wrapper">
      <div class="sub-title">OTP was sent to {{bookedBy.name }} on <span>{{bookedBy.phone}}</span></div>
      <button class="btn btn-resend shadow-none px-0" type="button" :disabled="!resendOtp" @click="handleResendOtp">Resend OTP</button>
    </div>
    <div>
      <otp-input class="mx-auto"
        ref="otpInput"
        input-classes="otp-input"
        separator=" "
        :digits="6"
        :should-auto-focus="true"
        type="text"
        separateInputClass="otp-input"
        placeholder=""
        @on-complete="handleOnComplete"
        
      />
    </div>
    </div>
    <button v-if="!otpSent" @click="sendOtp" :disabled="!this.bookedBy" class="btn shadow-none btn-book" type="button" >Send OTP</button>
    <button v-if="otpSent && bookedBy" :disabled="!this.otp"  @click="validateOtp" class="btn shadow-none btn-book" type="button" >Proceed</button>
</b-modal>
</template>

<script>
import  OtpInput  from  "otp-input-vue2";
  export default {
    components:{
		  OtpInput,
	  },
    data() {
      return {
        otpSent: false,
        otp: '',
        isOtpValid: false,
        resendOtp: false,
        users: [],
        bookedBy: null
      }
    },
    mounted(){
      this.getUsers();
    },
    methods: {
      resetForm(){
        if(!this.otpSent){
          this.bookedBy = null;
          this.resendOtp = false;
          this.otpSent = false;
          this.resendOtp = false,
          this.otp = '';
        }
      },
      getUsers(){
        this.$axios.get('inventories/fetch_users').then(res => {
          this.users = res.data.users
        })
      },
      handleDeselect(e){
        if(!e){
          this.otpSent = false;
          this.bookedBy = null;
        }
      },
      sendOtp(){
        this.otpSent = true;
        this.$axios.get(`otp/generate?sms_number=${this.bookedBy.phone}&event_type=eoi_visit`).then(res => {
          if(res.data && res.data.message == "Success"){
            this.$bvToast.toast('OTP sent Successfully', {
            title: 'Success',
            variant: 'success',
            solid: true
          })
          }
        }).catch( res => {
          this.$bvToast.toast('Unable to process the request at the moment. Please try again after sometime', {
            title: 'Oops',
            variant: 'danger',
            solid: true
          })
        })
        let vm = this;
        setTimeout(() => {
          vm.resendOtp = true;
        }, 30000);
      },
      handleOnComplete(value) {
        this.otp = value
      },
      handleResendOtp(){
        this.resendOtp = false;
        this.sendOtp();
      },
      validateOtp(){
        this.$axios.post(`otp/validate`,{
          "otp" : this.otp,
          "event_type": "eoi_visit",
          "sms_number": this.bookedBy.phone
        }).then((res)=>{
          console.log('working');
          this.$store.dispatch('setUser', this.bookedBy);
          this.$router.push('/floorplan');
        }).catch(res => {
          this.$bvToast.toast('Please check the OTP entered', {
            title: 'Invalid OTP',
            variant: 'danger',
            solid: true
          })
          console.log('not working');
        })
      }
    },
    computed: {
      selectedUnit() {
        return this.$store.getters.selectedUnit;
      },
    },
  }
</script>

<style>

.custom-select-tag .vs__dropdown-toggle {
  min-height: 54px;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.custom-select-tag.vs--open .vs__dropdown-toggle{
  border: 1px solid #80bdff;
}


.custom-select-tag .vs__clear{
  min-width: 40px;
  margin-bottom: 2px;
}


.custom-select-tag.selected{
  opacity: 1 !important;
}


.custom-select-tag .vs__dropdown-option{
  font-size: 13px;
  background-color: #fff !important;
  opacity: 1 !important;
}

.custom-select-tag .vs__dropdown-option--highlight{
  background-color: #80bdff !important;
}

.custom-select-tag .vs__selected{
  color: #000000;
}



.vs__search::placeholder{
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.otp-input {
  width: 42px;
  height: 65px;
  padding: 5px;
  margin: 0 6px;
  font-size: 24px;
  font-weight: 500;
  border-radius: 3px;
  border: 1px solid rgba(4, 153, 183, 0.2);
  text-align: center;
}

.otp-input:focus, .otp-input:focus-visible{
  outline: 1px solid #0499B7;
}

div.vue-otp-input>div.otp-wrapper>input.defualt-active-input{
  border: 1px solid #0499B7 !important;
  outline: 1px solid #0499B7 !important;
}

.otp-input.error {
  border: 1px solid red !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 768px) {
  .otp-input {
    width: 38px;
    height: 65px;
    padding: 5px;
    margin: 0 2px;
    font-size: 24px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid rgba(4, 153, 183, 0.2);
    text-align: center;
  }
}

</style>

<style scoped>

.form-input-group{
  position: relative;
  margin-bottom: 40px;
  font-size: 12px;
}

.form-input-group label{
  position: absolute;
  font-size: 10px;
  background-color: #fff;
  line-height: 1;
  top: 0;
  left: 20px;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0 3px;
}

.form-input-group input{
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding: 16px 18px;
  height: auto;
  box-shadow: none !important;
  border: 1px solid rgba(4, 153, 183, 0.2)
}


.btn-book{
  background-color: rgba(4, 153, 183, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: center;
  color: #fff !important;
  margin: 60px auto 16px;
  text-align: center;
  display: block;
  padding: 10px 60px;
}



.title{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  margin-bottom: 16px;
  color: #000;
  opacity: 1;
}

.wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.sub-title{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
}

.sub-title span{
  color: rgba(0, 0, 0, 1);
}


.btn-resend{
  color: rgba(4, 153, 183, 1);
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
}

.btn-resend:disabled{
  opacity: 0.3;
}

.btn-resend:hover{
  color: rgba(4, 153, 183, 1);
}


</style>